import React from "react";
import Img from "gatsby-image";
import {documentToReactComponents} from '@contentful/rich-text-react-renderer';

const BoxImageAndText = ({header, showHeader, media, mediaPosition, text, textBoxColor}) => (
    <>
        <div className="section">
            <div className="container">
                {showHeader &&
                <h2 className="title">{header}</h2>
                }
                <div className="columns">
                    <div className="column">
                        <div className="imageWrapper">
                            <Img fluid={media.fluid} alt={media.title}/>
                        </div>
                    </div>
                    <div className="column">
                        <div className="textWrapper" style={{backgroundColor: textBoxColor}}>
                            {documentToReactComponents(text.json)}
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </>
);

export default BoxImageAndText;
