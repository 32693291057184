import React from "react";
import {documentToReactComponents} from '@contentful/rich-text-react-renderer';

const Richtext = ({header, showHeader, text}) => (
    <>
        <div className="section">
            <div className="container">
                {showHeader &&
                <h2 className="title">{header}</h2>
                }
                <div className="text">
                    {documentToReactComponents(text.json)}
                </div>
            </div>
        </div>

    </>
);

export default Richtext;
