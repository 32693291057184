import React from "react";
import {Link} from "gatsby";
import Img from "gatsby-image";

const ServiceGallery = ({servicePages}) => (
    <div className="section">
        <div className="container">
            <h2 className="title">Unsere Referenzen</h2>
            <div className="columns is-multiline is-centered">
                {servicePages.map((value, index) => (
                    <div className="column is-one-quarter-desktop is-half-mobile">
                        <Link to={value.slug}>
                            {value.serviceImage &&
                                <Img fixed={value.serviceImage.fixed} alt={value.serviceImage.title}/>
                            }
                            <div className="title">{value.pageTitle}</div>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    </div>
);

export default ServiceGallery;
