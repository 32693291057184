import React from "react";
import SEO from "./seo";
import Richtext from "./richtext";
import BoxImageAndText from "./box-image-and-text";
import Gallery from "./gallery";
import ContentCarousel from "./content-carousel";
import ServiceGallery from "./service-gallery";

const Page = ({page, servicePages}) => {
    return (
        <>
            <SEO title={page.pageTitle}/>
            {page.content.map((value, index) => {
                switch (value.__typename) {
                    case "ContentfulComponentLoader":
                        if(value.component === "ServiceGallery") {
                            return <ServiceGallery key={index} servicePages={servicePages.nodes}/>
                        } else {
                            return <p>Component not implemented!</p>;
                        }
                    case "ContentfulContentCarousel":
                        return <ContentCarousel key={index} {...value}/>;
                    case "ContentfulGallery":
                        return <Gallery key={index} {...value}/>;
                    case "ContentfulBoxImageAndText":
                        return <BoxImageAndText key={index} {...value}/>;
                    case "ContentfulRichtext":
                        return <Richtext key={index} {...value}/>;
                    default:
                        return <div className="segment">
                            <div className="container">
                                <div key={index}><span>Component "{value.__typename}" not yet implemented.</span></div>
                            </div>
                        </div>

                }
            })}
        </>
    )
};

export default Page;
