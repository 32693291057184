import Img from 'gatsby-image';
import {chunk, sum} from 'lodash';
import React from 'react';

const Gallery = React.memo(function Gallery({header, showHeader, images, itemsPerRow}) {

    let tempSums = [];
    let aspectRatios = [];
    let rowAspectRatioSumsByBreakpoints = [];
    itemsPerRow = [3];

    function calculateBreakpointSums(image, index) {

        return rowAspectRatioSumsByBreakpoints.map(
            // Return a value for each breakpoint
            (rowAspectRatioSums, j) => {
                // Find out which row the image is in and get its aspect ratio sum
                const rowIndex = Math.floor(index / itemsPerRow[j]);
                const rowAspectRatioSum = rowAspectRatioSums[rowIndex];
                return `${(image.aspectRatio / rowAspectRatioSum) * 100}%`;
            },
        );
    }

    aspectRatios = images.map(image => image.fluid.aspectRatio);

    // For each breakpoint, calculate the aspect ratio sum of each row's images
    rowAspectRatioSumsByBreakpoints = itemsPerRow.map(
        itemsPerRow =>
            // Split images into groups of the given size
            chunk(aspectRatios, itemsPerRow).map(rowAspectRatios =>
                // Sum aspect ratios of images in the given row
                sum(rowAspectRatios),
            ),
    );

    images.map((image, i) => {
        tempSums[i] = calculateBreakpointSums(image.fluid, i);
    });

    return (
        <div className="section">
            <div className="container">
            {showHeader &&
                <h2 className="title">{header}</h2>
            }
            {images.map((image, i) => {
                    return (
                        <div key={i} className="galleryImageWrapper" style={{display: 'inline-block', width:tempSums[i]}}>
                            <Img fluid={image.fluid} alt={image.title} className="slideImage"/>
                        </div>
                    )
                }
            )}
            </div>
        </div>
    )
});

export default Gallery;