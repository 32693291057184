import React from "react";
import {Link} from "gatsby";
import Img from 'gatsby-image';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from 'react-responsive-carousel';

function mediaCode(media, index) {
    console.log(media);
    switch (media.media.file.contentType) {
        case "video/mp4":
            return <div>
                <Link to={media.link}>
                    <video muted={true} key={index} width="100%" playsInline={true} autoPlay={true}>
                        <source src={media.media.file.url} type={media.media.file.contentType}/>
                        Your browser does not support the video tag.
                    </video>
                </Link>
            </div>;
        case "image/jpeg":
            return <div>
                <Link to={media.link}>
                    <Img fixed={media.media.fixed} alt={media.media.title}/>
                </Link>
            </div>;
        default:
        //return null;
    }
}

const ContentCarousel = ({header, showHeader, content}) => (
    <div className="section">
        <div className="container">
            {showHeader &&
            <h2 className="title">{header}</h2>
            }
            <div className="carouselSliderWrapper">
                <Carousel interval={10000} infiniteLoop={true} autoPlay={true} showThumbs={false}>
                    {content.map((value, index) => {
                        console.log(value);
                        if (value.__typename === "ContentfulBoxImageAndText") {

                        }
                        if (value.__typename === "ContentfulMedia") {
                            return mediaCode(value, index);
                        }
                    })}
                </Carousel>
            </div>
        </div>
    </div>
);

export default ContentCarousel;
