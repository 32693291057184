import React from "react";
import {graphql} from "gatsby";
import Layout from "../components/layout";
import Page from "../components/page";

export default ({data}) => {
    return (
        <Layout>
            <Page {...data} />
        </Layout>
    )
}

export const query = graphql`
    query($pageID: String!) {
        servicePages: allContentfulPage(filter: {serviceNavigation: {eq: true}}, sort: {fields: pageTitle, order: ASC}) {
            nodes {
                slug
                pageTitle
                serviceImage {
                    fluid {
                        ...GatsbyContentfulFluid
                    }
                    fixed(resizingBehavior: FILL, height: 290, width: 290) {
                        ...GatsbyContentfulFixed
                    }
                }
            }
        }
        page: contentfulPage(id: { eq: $pageID }) {
            pageTitle
            content {
                __typename
                ... on Node {
                    ... on ContentfulComponentLoader {
                        component
                    }
                    ... on ContentfulRichtext {
                        header
                        showHeader
                        text {
                            json
                        }
                    }
                    ... on ContentfulBoxImageAndText {
                        header
                        showHeader
                        text {
                            json
                        }
                        media {
                            fluid(maxWidth: 767) {
                                ...GatsbyContentfulFluid
                            }
                        }
                        mediaPosition
                    }
                    ...on ContentfulGallery {
                        header
                        showHeader
                        images {
                            title
                            fluid(maxWidth: 767) {
                                ...GatsbyContentfulFluid
                            }
                            fixed(resizingBehavior: FILL, height: 290, width: 290) {
                                ...GatsbyContentfulFixed
                            }

                        }

                    }
                    ... on ContentfulContentCarousel {
                        name
                        content {
                            ... on ContentfulMedia {
                                name
                                media {
                                    title
                                    file {
                                        contentType
                                        url
                                    }
                                    fixed(height: 600) {
                                        ...GatsbyContentfulFixed
                                    }
                                }
                            }
                            ... on ContentfulBoxImageAndText {
                                header
                                showHeader
                                text {
                                    json
                                }
                                textBoxColor
                                mediaPosition
                                media {
                                    title
                                    file {
                                        contentType
                                        url
                                    }
                                    fluid(maxWidth: 767) {
                                        ...GatsbyContentfulFluid
                                    }
                                    fixed(resizingBehavior: FILL, height: 290, width: 290) {
                                        ...GatsbyContentfulFixed
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
